
const mutations = {
  TOGGLE_LOADER(state, loader) {
    state.isLoading = loader;
  },
  LOGOUT_USER(state) {
    localStorage.removeItem(state.localUserKey);
    state.activeUser = {};
    window.location = '/'
  },
  SET_AUTH_TOKEN(state, data){
    const expirationTime = new Date( new Date().getTime() + +data.expiresIn * 1000);
    const config = {
        token: data.accessToken,
        tokenExpirationDate: expirationTime.toISOString(),
        user: data.user,
    }
    const encryptedValue = btoa(escape(JSON.stringify(config)));
    localStorage.setItem(state.localUserKey, encryptedValue);
  },
  UPDATE_USER_INFO(state, user) {
    if(!user.id){return};
    if(state.activeUser){
      Object.assign(state.activeUser, user)
    }else{
      state.activeUser = {...user};
    }
    const get_localUser = localStorage.getItem(state.localUserKey);
    const storedData = JSON.parse(unescape(atob(get_localUser)));
    storedData.user = user;
    localStorage.setItem(state.localUserKey, btoa(escape(JSON.stringify(storedData))));
  },
  UPDATE_NOTIFICATION(state, notification){
    state.activeUser.notification = {...notification};
  },
  SET_COUNTRIES(state, countries){
    state.countries = [...countries];
  },
  SET_COUNTRY(state, country){
    state.country = {...country};
  },
  SET_CATEGORIES(state, categories){
    state.categories = [...categories];
  },

} 

export default mutations

