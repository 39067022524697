
import { createStore } from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import moduleLayout from "./modules/layout.js"
import moduleActivity from "./modules/activity.js"
import moduleOrder from './modules/order.js'

const store = createStore({
    state,
    actions,
    getters,
    mutations,
    modules: {
        layout: moduleLayout,
        activity: moduleActivity,
        orderList: moduleOrder,
    },
    strict: process.env.NODE_ENV !== 'production',
})

export default store
