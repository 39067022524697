import env from '../env.js'
import countries from './db/countries.js'
import { getUserData } from '@/auth/utils'

const state = {
    isLoading: false,
    activeUser: getUserData(),
    domain: env.domain,
    localUserKey: env.localUserKey,
    appName: env.appName,
    config:{
        shortDesc: "Top-rated branding agency",
        appEmail: "support@risingwork.com"
    },
    windowWidth: null,
    countries: countries,
    categories: []
}

export default state
