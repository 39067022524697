export default [
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
        path: '/main',
        redirect: '/',
        component: () => import('@/layouts/Main.vue'),
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: () => import('@views/Dashboard.vue'),
                meta: {
                  breadcrumb: [
                    {
                      text: "Dashboard",
                      active: true
                    }
                  ],
                  title: 'Dashboard',
                  authRequired: true,
                }
            },
            {
              path: '/activity',
              name: 'activity',
              component: () => import('@views/activity/ActivityList.vue'),
              meta: {
                  breadcrumb: [
                    {
                      text: "Dashboard",
                      to:{ path:"/"}
                    },
                    {
                      text: "Activities",
                      active: true
                    }
                ],
                title: "Activities",
                authRequired: true,
              }
            },
            {
                path: '/orders',
                name: 'orders',
                component: () => import('@views/orders/OrderList.vue'),
                meta: {
                    breadcrumb: [
                      {
                        text: "Dashboard",
                        to:{ path:"/"}
                      },
                      {
                        text: "Orders",
                        active: true
                      }
                  ],
                  title: "Orders",
                  authRequired: true,
                }
            },
            {
              path: '/orders/:orderId(\\d+)',
              name: 'order-view',
              redirect: to => {
                return { path: `/orders/${to.params.orderId}/overview` }
              },
              meta: {
                title: "Vew Order",
                authRequired: true,
              },
            },
            {
              path: '/orders/:orderId(\\d+)/overview',
              name: 'order-overview',
              component: () => import('@views/orders/OrderOverview.vue'),
              meta: {
                title: "Order Overview",
                authRequired: true,
              },
            },
            {
              path: '/settings/profile',
              name: 'setting-profile',
              component: () => import('@views/settings/account/UserEdit.vue'),
              meta: {
                breadcrumb: [
                    {
                      text: "Dashboard",
                      to:{ path:"/"}
                    },
                    {
                      text: "Account",
                      active: true
                    }
                ],
                title: 'Profile',
                authRequired: true,
              },
            },
        ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '/full',
        component: () => import('@/layouts/Full.vue'),
        children: [
          {
            path: '/login',
            name: 'login',
            component: () => import('@/views/pages/Login.vue'),
            meta: {
              guestRequired: true,
              title: "Login"
            }
          },
          {
            path: '/register',
            name: 'register',
            component: () => import('@/views/pages/Register.vue'),
            meta: {
              guestRequired: true,
              title: "Register"
            }
          },
          {
              path: '/error-404',
              name: 'error-404',
              component: () => import('@/views/pages/Error404.vue'),
              meta: {
                title: "404 - Page not Found"
              }
          },
          {
              path: '/error-500',
              name: 'error-500',
              component: () => import('@/views/pages/Error500.vue'),
              meta: {
                title: "500 - Server error"
              }
          },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '/:any(.*)',
        redirect: '/error-404'
    },

]